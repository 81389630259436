import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

/**
 * Composant FacetteProfs permettant de construire la liste des facettes des professions
 */
class FacetteProfs extends Component {

    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
    }

    /**
     * Vérifie si une facette est cochée
     * @param {*} facette : Code de la facette sélectionnée
     */
    /*isChecked(facette, e) {
        //Reprend le tableau des professions défini dans les variables d'état
        const professions = this.props.listProfessions;
        //Met à jour l'état de 'checked' dans le tableau professions
        professions.forEach((prof) => {
            if (prof.code === facette.key) {
                e.target.checked = prof.checked;
                return prof.checked;
            } else if (this.props.isCheckedProf === false) {
                e.target.checked = false;
            }
        });
    }*/

    /**
     * Fonction déclenchée au moment de cocher/décocher une facette
     * @param {*} id : la valeur de la facette - code profession (ex.: '38')
     * @param {*} type : ici, professions
     * @param {*} e : événement onChange
     */
    facetteClick(id, type, e) {
        this.props.handleFacetteClick(id, type, e);
    }

    /**
     * Rendu du Composant FacetteProfs
     * @returns : template HTML avec les différents appels aux Composants et fonctions JS
     */
    render() {
        return (
            <div className='border' style={{ marginTop: "20px" }}>
                <ul className='list-group'>
                    <li className='list-group-item border-0'><h5>Professions</h5></li>
                    <PerfectScrollbar>
                        <div style={{ height: "1000px" }}>
                            {this.props.homeToFacette.map(facette => {
                                return (
                                    <li className='list-group-item border-0' key={facette.key}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox onChange={this.facetteClick.bind(this, facette.key, "professions")} name={facette.key} size='small' sx={{
                                                '&.Mui-checked': {
                                                    color: '#CA0538',
                                                },
                                                paddingTop: '0px',
                                                paddingBottom: '0px'
                                            }} />} label={this.props.listProfessions.map((prof) => {
                                                if (prof.code === facette.key) {
                                                    return prof.name + ' (' + facette.count + ')';
                                                }
                                            })} />
                                        </FormGroup>
                                    </li>
                                )
                            })}
                        </div>
                    </PerfectScrollbar>
                </ul>
            </div>
        )
    }
}

export default FacetteProfs;