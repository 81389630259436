import React, { Component } from 'react';
import { Slider } from '@mui/material';

/**
 * Composant Cursor permettant de rechercher du plus pertinent au plus récent
 */
class Cursor extends Component {

    /**
     * Fonction déclenchée au changement de position du curseur
     * @param {*} e : événement on Change
     * @param {*} value : position du curseur
     */
    handleChangeCursor(e, value) {
        //Appel de la fonction définie dans l'attribut handleChange présente dans la page Home.js 
        this.props.handleChange(value);
    }

    /**
     * Rendu du Composant Cursor
     * @returns : template HTML avec les différents appels aux variables passées en paramètre depuis la page Home.js et aux fonctions JS
     */
    render() {
        return (
            <div className='col-6 d-flex justify-content-around align-items-center'>
                <p>Plus Pertinent</p>
                <Slider aria-label="Volume" value={this.props.valueSearch} onChangeCommitted={this.handleChangeCursor.bind(this)} sx={{ width: 250, color: '#CA0538' }} min={0} max={10} valueLabelDisplay="auto" step={1} />
                <p>Plus Récent</p>
            </div>
        )
    }
}

export default Cursor;