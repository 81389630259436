import React, { Component } from 'react';
import { Pagination } from '@mui/material';

/**
 * Composant Pagination permettant de naviguer parmi les résultats de recherche
 */
class PaginationCustom extends Component {

    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            searchResult: true
        }
    }

    /**
     * Fonction déclenchée au moment de changer de page
     * @param {*} e : événement onChange
     * @param {*} value : numéro de page sélectionnée
     */
    changePage(e, value) {
        //Appel de la fonction définie dans l'attribut setPage présente dans la page Home.js
        this.props.setPage(value);
    }

    /**
     * Fonction déclenchée permettant de calculer le nombre de pages total en fonction du nombre de résultats de recherche
     * @param {*} hits : Nombre total de résultats
     * @returns : Nombre total de pages (en prenant en compte qu'il existe 7 résultats par page)
     */
    calculatePage(hits) {
        return Math.ceil(hits / 7);
    }

    /**
     * Rendu du Composant Pagination
     * @returns : template HTML avec les différents appels aux variables passées en paramètre depuis la page Home.js et aux fonctions JS
     */
    render() {
        return (
            <div className='d-flex justify-content-center'>
                <Pagination onChange={this.changePage.bind(this)} count={this.calculatePage(this.props.totalHits)} page={this.props.valuePage} sx={{
                    "& .MuiPaginationItem-root": {
                        '&.Mui-selected': {
                            background: '#CA0538',
                            color: 'white',
                        }
                    }
                }} />
            </div>
        );
    }
}

export default PaginationCustom;