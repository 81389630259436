import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoCetim from '../../assets/images/logo_cetim.svg';

/**
 * Composant Navbar permettant de construire une barre de navigation
 */
class Navbar extends Component {
    /**
     * Rendu du Composant Navbar
     * @returns : template HTML du composant Navbar
     */
    render() {
        return (<nav className="navbar navbar-expand-lg navbar-light bg-light">
            <ul className="navbar-nav mr-auto d-flex align-items-center">
                <li><Link to={'/'} className="nav-link"><img src={logoCetim} alt="Logo Cetim" style={{ width: 111, height: 65 }} /></Link></li>
                <li className="nav-link"><h3>MÉCATHÈQUE, la base de connaissances du manufacturing
                </h3></li>
            </ul>
        </nav>);
    }
}

export default Navbar;