import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

/**
 * Composant FacetteTypes permettant de construire la liste des facettes des types de documents
 */
class FacetteTypes extends Component {

    /**
     * Fonction déclenchée au moment de cocher/décocher une facette
     * @param {*} id : la valeur de la facette (ex.: 'Veille technologique')
     * @param {*} type : ici, type de documents
     * @param {*} e : événement onChange
     */
    facetteClick(id, type, e) {
        //Appel de la fonction définie dans l'attribut handleFacetteClick présente dans la page Home.js
        this.props.handleFacetteClick(id, type, e);
    }

    /**
     * Rendu du Composant FacetteTypes
     * @returns : template HTML avec les différents appels aux Composants et fonctions JS
     */
    render() {
        return (
            <div className='border' style={{ marginTop: "20px" }}>
                <ul className='list-group'>
                    <li className='list-group-item border-0'><h5>Type de documents</h5></li>
                    <PerfectScrollbar>
                        <div style={{ height: "200px" }}>
                            {this.props.homeToFacette.map(facette => {
                                return (
                                    <li className='list-group-item border-0' key={facette.key}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox onChange={this.facetteClick.bind(this, facette.key, "typeDoc")} checked={this.props.isCheckedType} name={facette.key} size='small' sx={{
                                                '&.Mui-checked': {
                                                    color: '#CA0538',
                                                },
                                                paddingTop: '0px',
                                                paddingBottom: '0px'
                                            }} />} label={facette.key + ' (' + facette.count + ')'} />
                                        </FormGroup>
                                    </li>
                                )
                            })}
                        </div>
                    </PerfectScrollbar>
                </ul>
            </div>
        )
    }
}

export default FacetteTypes;