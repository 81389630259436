import React, { Component } from 'react';
import "./styles.css";

import FacetteProfs from '../../components/facette/FacetteProfs';
import FacetteTypes from '../../components/facette/FacetteTypes';
import Resultat from '../../components/resultat/Resultat';
import PaginationCustom from '../../components/resultat/PaginationCustom';
import Search from '../../components/search/Search';
import Cursor from '../../components/search/Cursor';

import elasticService from '../../service/ElasticService';
import StatsMecatheque from '../../components/stats/StatsMecatheque';

/**
 * Page d'Accueil de la Mécathèque
 */
class Home extends Component {

    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            err: null,
            posts: [],
            message: '',
            results: [],
            valueCursor: 1,
            rescoreTime: 0,
            valueSearch: [],
            firstSearch: true,
            facetteProfessions: [],
            facetteTypes: [],
            checked: false,
            profsAgregas: [],
            typesAgregas: [],
            totalHits: 0,
            indexPage: 1,
            isWaitingResult: false,
            isCheckedTypes: false,
            professions: [
                { name: 'Articles culinaires', code: '24', checked: false },
                { name: 'Chaudonnerie, tuyauterie, tôlerie', code: '15', checked: false },
                { name: 'Dispositifs médicaux', code: '2E', checked: false },
                { name: 'Décolletage', code: '18', checked: false },
                { name: 'Découpage-Emboutissage', code: '12', checked: false },
                { name: 'Fixations', code: '21', checked: false },
                { name: 'Fonderie', code: '1A', checked: false },
                { name: 'Forge', code: '11', checked: false },
                { name: 'Fours et Matériels pour la Sidérurgie', code: '3A', checked: false },
                { name: 'Machines agricoles', code: '31', checked: false },
                { name: 'Machines thermodynamiques', code: '33', checked: false },
                { name: 'Machines-outils et productique', code: '32', checked: false },
                { name: 'Manutention-levage, Stockage', code: '39', checked: false },
                { name: 'Matériels de bureau', code: '2F', checked: false },
                { name: 'Matériels destinés à l\'alimentaire', code: '3B', checked: false },
                { name: 'Matériels pour la chimie', code: '3C', checked: false },
                { name: 'Matériels pour le papier, le carton, l\'imprimerie', code: '37', checked: false },
                { name: 'Matériels textiles', code: '36', checked: false },
                { name: 'Mesure et Pesage', code: '2D', checked: false },
                { name: 'Mobilier', code: '2A', checked: false },
                { name: 'Moteurs, Compresseurs, Turbines', code: '34', checked: false },
                { name: 'Moules', code: '19', checked: false },
                { name: 'Mécanique industrielle', code: '14', checked: false },
                { name: 'Outillage à main', code: '22', checked: false },
                { name: 'Outils coupants', code: '25', checked: false },
                { name: 'Pompes', code: '29', checked: false },
                { name: 'Quincaillerie', code: '23', checked: false },
                { name: 'Ressorts', code: '2C', checked: false },
                { name: 'Robinetterie', code: '27', checked: false },
                { name: 'Roulements', code: '2B', checked: false },
                { name: 'Soudage', code: '16', checked: false },
                { name: 'Traitements de surface', code: '13', checked: false },
                { name: 'Transformation des plastiques', code: '35', checked: false },
                { name: 'Transmissions hydrauliques et pneumatiques', code: '28', checked: false },
                { name: 'Transmissions mécaniques', code: '26', checked: false },
                { name: 'Travaux Publics, Mines, Forage', code: '38', checked: false },
                { name: 'Non défini', code: 'undefined', checked: false }
            ]
        };
    }

    /**
     * Appel au service ElasticSearch pour la recherche de documents (serveur-serveur)
     *
     * @param {*} query : Liste des mot(s)-clé(s) saisie par l'utilisateur
     * @param {*} rescore_time : Valeur entière (entre 0 et +10) du curseur pertinence/récence
     * @param {*} professions : Liste des facettes professions sélectionnées
     * @param {*} typesDoc : Liste des facettes types de documents sélectionnées
     * @param {*} startPage : Numéro de page de recherche
     * @param {*} firstSearch : Booléen pour savoir si c'est la recherche par défaut (par ordre chronologique)
     */
    callElasticForSearch(query, rescore_time, professions, typesDoc, startPage, firstSearch) {
        elasticService.searchES(query, rescore_time, professions, typesDoc, startPage, firstSearch).then(response => {
            this.setState({ posts: response["hits"], profsAgregas: response["professions"], typesAgregas: response["types"],
             totalHits: response["nbHits"], isWaitingResult: false });
        });
    }

    /**
     * Appel au service ElasticSearch pour la suggestion de mots-clés (client-serveur)
     *
     * @param {*} postData : Requête construite pour avoir une liste de suggestions
     */
    callElasticForSuggest(postData) {
        elasticService.suggestES(postData).then(json => {
            this.setState({ results: json.hits.hits });
        });
    }

    /**
     * Actions à réaliser au lancement de l'application
     */
    componentDidMount() {
        const query = this.state.valueSearch;
        const rescore_time = this.state.rescoreTime;
        const professions = this.state.facetteProfessions;
        const typesDoc = this.state.facetteTypes;
        const startPage = 0;
        // firstSearch désigne que c'est l'action par défaut de recherche, sans aucune sélection
        const firstSearch = this.state.firstSearch;
        this.setState({ valueCursor: 0 }); // +10 aurait été trompeur -> on met au centre, même si les docs seront les + récents
        this.callElasticForSearch(query, rescore_time, professions, typesDoc, startPage, firstSearch);
    }

    /**
     * Fonction appelée au changement de la saisie de l'utilisateur (barre de recherche)
     * @param {*} e : événement onChange
     * @param {*} value : valeur saisie par l'utilisateur
     */
    onValueChangeFromHome(e, value) {
        if (value !== "") {
            //Requête construite pour chercher toutes les suggestions de mots-clés
            const postData = {
                "query": {
                    "multi_match": {
                        "query": value,
                        "type": "bool_prefix",
                        "fields": [
                            "expression",
                            "expression._2gram",
                            "expression._3gram"
                        ]
                    }
                }
            };
            //Appel au service Elastic pour la suggestion de mots-clés
            this.callElasticForSuggest(postData);
        }
    };

    /**
     * Fonction appelée au clic de l'utilisateur sur le bouton de recherche
     * Elle prend en compte la valeur du curseur, le ou les mots-clés saisis, les facettes sélectionnées et le numéro de page de recherche
     */
    handleClickFromHome() {
        const query = this.state.valueSearch;
        // const rescore_time = 0;
        const professions = [];
        const typesDoc = [];
        const startPage = 0;
        let copyProfessions = [...this.state.professions];
        let copyFacetteProfessions = [...this.state.facetteProfessions];
        for (const item of copyProfessions) {
            item.checked = false;
        }
        copyFacetteProfessions = [];
        this.setState({ facetteProfessions: copyFacetteProfessions, professions: copyProfessions, isCheckedTypes: false, indexPage: 1 });
        if (query.length === 0) {
            // cas d'une requête sur toute la base --> on pousse le curseur à droite (docts les + récents)
            this.setState({ firstSearch: true, valueCursor: 10 });
            this.callElasticForSearch(query, 10, professions, typesDoc, startPage, true);
        } else {
            // un terme a été saisi -> on ramène le curseur à gauche
            this.setState({ firstSearch: false, valueCursor: 0 });
            this.callElasticForSearch(query, 0, professions, typesDoc, startPage, false);
        }
    }

    /**
     * Fonction appelée au moment du changement de valeur du curseur
     * Les (11) valeurs vont de 0 à +10 ; à la valeur 0, aucune "surcote" n'est n'appliquée à la récence.
     * (pertinence seule)
     * Pour une valeur >= 1 et d'autant plus grande, la "surcote" (par rescoring) de la récence s'appliquera sur des documents saisis
     * dans une période passée (partant d'aujourd'hui) d'autant plus courte, et avec un "boost" d'autant plus grand que le document est ancien.
     *
     * @param {*} newValue : la nouvelle valeur saisie par l'utilisateur à l'aide du curseur
     */
    handleCursorChange(newValue) {
        this.setState({
            valueCursor: newValue,
            rescoreTime: newValue,
            indexPage: 1
        });
        // Appel au service ElasticSearch pour afficher directement les résultats en fonction des nouveaux critères
        this.callElasticForSearch(this.state.valueSearch, newValue, this.state.facetteProfessions, this.state.facetteTypes, 0, this.state.firstSearch);
    }

    /**
     * Ajout des mots-clés saisis et sélectionnés par l'utilisateur
     * @param {*} value : mot-clé saisi et validé par l'utilisateur
     */
    selectValueFromHome(value) {
        this.setState({ valueSearch: value });
    }

    /**
     * Gère la sélection de facettes pour les professions et les types de documents
     * @param {*} id : soit le code profession, soit la valeur du type de document
     * @param {*} type : facette de type professions ou types
     * @param {*} e : évenement onChange
     */
    facetteClick(id, type, e) {
        const search_query = this.state.valueSearch;
        // Récupation de la liste des professions et types de documents actuelles
        let arrayProfessions = [...this.state.facetteProfessions];
        let arrayTypes = [...this.state.facetteTypes];
        let copyProfessions = [...this.state.professions];
        if (type === "professions") {
            // Recherche de la position de la profession dans la liste des professions
            const index = copyProfessions.findIndex((list) => list.code === e.target.name);
            // Ajout de la profession cochée dans une liste qui sera envoyée à Elastic
            if (e.target.checked === true) {
                arrayProfessions.push(id);
                copyProfessions[index].checked = true;
                this.setState({ facetteProfessions: arrayProfessions, professions: copyProfessions });
            } else {
                // Supprime les professions décochées de la liste
                copyProfessions[index].checked = false;
                let indexArray = arrayProfessions.indexOf(id);
                if (indexArray !== -1) {
                    arrayProfessions.splice(indexArray, 1);
                    this.setState({ facetteProfessions: arrayProfessions, professions: copyProfessions });
                }
            }
        } else {
            // Ajout du type de document coché dans une liste qui sera envoyée à Elastic
            if (e.target.checked === true) {
                arrayTypes.push(id);
                this.setState({ facetteTypes: arrayTypes, isCheckedTypes: true });
            } else {
                // Suppression des types de documents décochés de la liste
                let index = arrayTypes.indexOf(id);
                if (index !== -1) {
                    arrayTypes.splice(index, 1);
                    this.setState({ facetteTypes: arrayTypes, isCheckedTypes: false });
                }
            }
        }
        this.setState({ indexPage: 1 });
        // Appel au service ElasticSearch pour afficher directement les résultats en fonction des nouveaux critères
        this.callElasticForSearch(search_query, this.state.rescoreTime, arrayProfessions, arrayTypes, 0, this.state.firstSearch);

    }

    /**
     * Changement de page de recherche (précédent ou suivant)
     * @param {*} value : le numéro de page
     */
    changePage(value) {
        // Calcul de la valeur "from" d'Elastic qui permet de rechercher à partir d'un certain nombre de documents
        let indexOfLastPost = (value - 1) * 7;
        this.setState({ indexPage: value });
        // Appel au service ElasticSearch pour afficher directement les résultats
        this.callElasticForSearch(this.state.valueSearch, this.state.rescoreTime, this.state.facetteProfessions, this.state.facetteTypes, indexOfLastPost, this.state.firstSearch);
    }

    /**
     * Rendu de la page d'Accueil avec les différents composants
     * @returns : template HTML avec les différents appels aux fonction JS et aux Composants
     */
    render() {
        const { posts } = this.state;
        const { results } = this.state;
        const { profsAgregas } = this.state;
        const { typesAgregas } = this.state;
        const { professions } = this.state;
        const valueCursor = this.state.valueCursor;
        const hits = this.state.totalHits;
        const index = this.state.indexPage;
        const isCheckedTypes = this.state.isCheckedTypes;
        return (
            <div className="App" >
                <header className="App-header">
                    <StatsMecatheque />
                    <div className='row'>
                        <Search resultsES={results} onValueChange={this.onValueChangeFromHome.bind(this)} handleClick={this.handleClickFromHome.bind(this)} selectValue={this.selectValueFromHome.bind(this)} />
                        <Cursor valueSearch={valueCursor} handleChange={this.handleCursorChange.bind(this)} />
                    </div>
                </header>
                <main>
                    <div className='row'>
                        <div className='col-3'>
                            <div>
                                <FacetteTypes homeToFacette={typesAgregas} handleFacetteClick={this.facetteClick.bind(this)} isCheckedType={isCheckedTypes} />
                                <FacetteProfs homeToFacette={profsAgregas} listProfessions={professions} handleFacetteClick={this.facetteClick.bind(this)} isCheckedProf={professions.checked} />
                            </div>
                        </div>
                        <div className='col-9' style={{ marginTop: "20px" }}>
                            <div style={{ marginLeft: "50px", fontWeight: 'bold', marginBottom: '20px' }}>
                                <span style={{ color: "#CA0538" }}>{hits}</span>
                                {(hits > 1) ? <span> RESULTATS TROUVES</span> : <span> RESULTAT TROUVE</span>}
                            </div>
                            {hits > 0 &&
                                <div>
                                    <Resultat results={posts} />
                                    <PaginationCustom totalHits={hits} setPage={this.changePage.bind(this)} valuePage={index} />
                                </div>
                            }

                        </div>
                    </div>
                </main >

            </div >
        );
    }
}

export default Home;