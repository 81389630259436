import React from 'react';
import { useParams } from 'react-router-dom';

/**
 * Composant Router permettant de gérer les routes dans l'application React
 * @param {*} WrappedComponent : Composant permettant de réaliser des routes
 * @returns : Composant WrappedComponent
 */
const withRouter = WrappedComponent => props => {
  const params = useParams();
 
  return (
    <WrappedComponent
      {...props}
      params={params}
    />
  );
};
 
export default withRouter;