import React, { Component } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

/**
 * Composant Recherche contenant une barre (avec auto-complétion) et un bouton de recherche
 */
class Search extends Component {
    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            searchResult: true,
            value: 0
        }
    }

    /**
     * Fonction déclenchée au moment de la saisie d'un mot-clé dans la barre de recherche
     * @param {*} e : événement onInputChange
     * @param {*} value : valeur saisie par l'utilisateur dans la barre de recherche
     */
    onValueChangeSearch(e, value) {
        //Appel de la fonction définie dans l'attribut onValueChange présente dans la page Home.js
        this.props.onValueChange(e, value);
    };

    /**
     * Fonction déclenchée au moment de la recherche de fiches Mécathèque grâce au bouton de recherche
     * @param {*} e :  événement onClick
     */
    handleClick(e) {
        //Appel de la fonction définie dans l'attribut handleClick présente dans la page Home.js
        this.props.handleClick();
    }

    /**
     * Fonction déclenchée au moment de la sélection de mots-clés
     * @param {*} e : évement onChange
     * @param {*} value : liste des mots-clés sélectionnées
     */
    selectValue(e, value) {
        //Appel de la fonction définie dans l'attribut selectValue présente dans la page Home.js
        this.props.selectValue(value);
    }

    /**
     * Rendu du Composant de recherche
     * @returns : template HTML avec les différents appels aux fonction JS
     */
    render() {
        // Permet d'accepter la case (sensitive : false, insensitive : true)
        // A mettre dans le composant Autcomplete ; filterOptions={filterOptions}
        const filterOptions = createFilterOptions({
            ignoreAccents: true,
            ignoreCase: true,
            //limit: 7,
        });

        return (
            <div className='col-6 d-flex justify-content-around align-items-center'>
                <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    onInputChange={this.onValueChangeSearch.bind(this)}
                    onChange={this.selectValue.bind(this)}
                    filterOptions={filterOptions}
                    multiple
                    sx={{ width: 500 }}
                    options={this.props.resultsES.map(option => option._source.expression)}
                    renderInput={(params) => <TextField {...params} label="Rechercher" sx={{
                        "& label.Mui-focused": {
                            color: '#CA0538'
                        },

                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: '#CA0538'
                            }
                        }
                    }} />}
                />
                <button className="btn btn-primary" onClick={this.handleClick.bind(this)}><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
            </div>
        )
    }
}

export default Search;