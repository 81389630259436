import React, { Component } from 'react';
// import logoCetim from '../../assets/images/Logo-CETIM.png';
import logoCetim from '../../assets/images/logo_cetim.svg';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faClipboard, faBook } from '@fortawesome/free-solid-svg-icons';

import "./styles.css";

/**
 * Composant Resultat permettant d'afficher les fiches Mécathèque provenant d'ElasticSearch
 */
class Resultat extends Component {

    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            searchResult: true,
            value: 0
        }
    }

    /**
     * Rendu du Composant Résultat
     * @returns : template HTML avec les différents appels aux variables passées en paramètre depuis la page Home.js
     */
    render() {
        return (
            <div>
                {
                    this.props.results.map(post => {
                        return (
                            <div className='row' key={post.id} style={{ height: 'auto' }}>
                                <div className='col-3 d-flex justify-content-center align-items-center'>
                                    {(post.source.img_url === "NONE" || post.source.img_url === "") ? <img src={logoCetim} alt="Logo Cetim" style={{ width: 111, height: 65 }} /> : <img src={post.source.img_url} alt="Logo Fiche Meca" />}
                                </div>
                                <div className='col-9'>
                                    <div className='border border-secondary rounded' style={{ fontSize: '12px', width: 'fit-content', padding: '10px', marginBottom: '20px' }}>
                                        <span style={{ textTransform: "uppercase", fontWeight: 'bold' }}>{post.source.object_type}</span>
                                        <span>&nbsp; - &nbsp;</span>
                                        <span style={{ textTransform: "uppercase" }}>{post.source.parent_type}</span>
                                        <span>&nbsp; - &nbsp;</span>
                                        {(post.source.mod_date === "") ? <span>{new Date(post.source.publ_date).toLocaleDateString()}</span> : <span>{new Date(post.source.mod_date).toLocaleDateString()}</span>}
                                    </div>
                                    <a style={{
                                        fontWeight: 'bold',
                                        color: 'black',
                                        textDecoration: 'none'
                                    }} className="customLink" href={post.source.url_alias} target='_blank'>{post.source.title}</a>
                                    <p>{post.source.description.substring(0, 160) + ' ...'}</p>
                                    <hr />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        )
    }
}
/* <Link to={'/fiche/' + post.id} className="customLink" style={{
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none'
}} target='_blank'>{post.source.title}</Link>*/

export default Resultat;