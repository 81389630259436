import React, { Component } from 'react';
import withRouter from '../../service/withRouter';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import logoCetim from '../../assets/images/logo_cetim.svg';

/**
 * Page de détail d'une fiche Mécathèque
 */
class Fiche extends Component {

    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            firstPosts: []
        };
    }

    /**
     * Actions à réaliser au lancement de l'application
     */
    componentDidMount() {
        this.searchDoc();
    }

    /**
     * Appel du service ElasticSearch pour construire la rubrique "Voir Aussi" d'une fiche Mécathèque
     */
    searchDoc() {
        //Appel au serveur backend (Spring Boot)pour interroger l'API Elastic
        fetch('/api/searchById?id=' + this.props.params.id, {
            method: 'GET',
        })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Server responds with error! " + response.statusText);
                }
                return response.json()
            })
            .then(json => {
                //Retourne le document consulté + les documents "Voir Aussi"
                this.setState({
                    firstPosts: json[0],
                    posts: json.slice(1, json.length)
                });
            });
    }

    /**
     * Rendu de la page Fiche avec ses différents composants
     * @returns : template HTML avec les différents appels aux composants et fonctions JS
     */
    render() {
        const { posts } = this.state;
        const { firstPosts } = this.state;
        return (
            <div className="App">
                <header className="App-header">
                </header>
                <div className="row">
                    <div className='col-9'>
                        <h2>{firstPosts.titre}</h2>
                        <p>{firstPosts.date_modification}</p>
                        {firstPosts.image !== "null" && <img src={firstPosts.image} alt="Logo Fiche Meca" />}
                        {firstPosts.image === "null" && <img src={logoCetim} alt="Logo Cetim" style={{ width: 111, height: 65 }} />}
                        <p>{firstPosts.description}</p>
                    </div>
                    <div className='col-3'>
                        <h4>Voir aussi</h4>
                        <hr />
                        <div style={{ height: "500px" }}>
                            <PerfectScrollbar>
                                {posts.map(post => {
                                    return (
                                        <div key={post.id}>
                                            <Link to={'/fiche/' + post.id} style={{ fontWeight: 'bold', color: 'black', textDecoration: 'none' }} target='_blank'>{post.source.titre}</Link>
                                            <p>{post.source.description.substring(0, 160) + ' ...'}</p>
                                            <p>{post.source.date_modification}</p>
                                            <hr />
                                        </div>
                                    );
                                })}
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Fiche);