import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home';
import Fiche from './pages/fiche/Fiche';
import Navbar from './components/navbar/Navbar';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className='container'>
          <Navbar/>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/fiche/:id' element={<Fiche />} />
          </Routes>
        </div>
      </Router>
    );
  }
}
export default App;
