import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileAlt, faUserTie, faUsers } from '@fortawesome/free-solid-svg-icons';

import "./styles.css";

class StatsMecatheque extends Component {

    render() {
        return (
            <div className='row' style={{marginTop : "50px"}}>
                <div className='col text-center'>
                    <FontAwesomeIcon icon={faDownload} className='icon' />
                    <p className='number'>50 000</p>
                    <p className='text'>Téléchargements</p>
                </div>
                <div className='col text-center'>
                    <FontAwesomeIcon icon={faFileAlt} className='icon' />
                    <p className='number'>350</p>
                    <p className='text'>Nouveaux documents</p>
                </div>
                <div className='col text-center'>
                    <FontAwesomeIcon icon={faUserTie} className='icon' />
                    <p className='number'>700</p>
                    <p className='text'>Spécialistes</p>
                </div>
                <div className='col text-center'>
                    <FontAwesomeIcon icon={faUsers} className='icon' />
                    <p className='number'>35 000</p>
                    <p className='text'>Visites par mois</p>
                </div>
            </div>
        );
    }
}

export default StatsMecatheque;